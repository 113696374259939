import { IonItem, IonSpinner } from '@ionic/react'
import { Button } from '@radix-ui/themes'
import { ColumnDef, createColumnHelper, FilterFnOption, Row, SortingFnOption } from '@tanstack/react-table'
import { AnyColumnProps } from 'components/editable-table/ColumnProps'
import { EditableTable } from 'components/editable-table/EditableTable'
import {
  DateCellFormat,
  DollarsCellFormat,
  FullPercentCellFormat,
  NumberCellFormat,
  StringCellFormat,
} from 'components/editable-table/EditableTextCellFormat'
import EditableTanstackTable, { DeleteCell } from 'components/editable-tanstack-table/EditableTanstackTable'
import ErrorAlertModal from 'components/ErrorAlertModal'
import { createMarketOpenDate, numberToReadableDollarString } from 'plural-shared/utils'
import { usePluralAuth } from 'providers/PluralAuthProvider'
import { useVanillaTRPC } from 'providers/TRPCProvider'
import { useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useParams } from 'react-router-dom'
import { ArrayElement, Optional } from 'safety'
import { DropdownSearchOption } from 'several/components/DropdownSearch'
import { AuthStatus } from 'utils/authStatus'
import { RouterOutputs, trpc } from 'utils/trpc'
import { TextInputSelectOption } from './company/TextInputSelect'

type RouterJournalEntry = ArrayElement<RouterOutputs['journalEntries']['getJournalEntries']['journalEntries']>
type RouterFundCommit = ArrayElement<RouterOutputs['journalEntries']['getFundCommitsQuery']['fundCommits']>
type RouterUser = ArrayElement<RouterOutputs['user']['adminGetAllUsers']['users']>

enum JournalHeaders {
  Date = 'Date',
  ContractDate = 'Contract Date',
  Entity = 'Legal Entity',
  Amount = 'Amount',
  Company = 'Company',
  IsInTransit = 'In Transit',
  BasePrice = 'Base Share Price',
  minCagr = 'Minimum CAGR',
  numberOfShares = 'Number of Shares',
  reservedShares = 'Shares Reserved',
  IsUndisclosed = 'Is Undisclosed',
  ShareType = 'Share Type',
}

enum UserHeaders {
  Email = 'Email',
  FirstName = 'FirstName',
  LastName = 'LastName',
  Handle = 'Handle',
}

type CreatedUserObject = {
  [UserHeaders.Email]: string
  [UserHeaders.FirstName]: string
  [UserHeaders.LastName]: string
  [UserHeaders.Handle]: string
}

enum FundCommitHeaders {
  WireDate = 'Wire Date',
  SubscribeDate = 'Subscribe Date',
  Amount = 'Amount',
  Entity = 'Legal Entity',
  ManagementFees = 'Management Fees',
  CarryPercentage = 'Carry Percentage',
}

type RouterCompany = ArrayElement<RouterOutputs['companies']['allForAdminSheet']>

type CreatedJournalObject = {
  [JournalHeaders.Date]: Date
  [JournalHeaders.ContractDate]: Date
  [JournalHeaders.Amount]: number
  [JournalHeaders.Entity]: TextInputSelectOption
  [JournalHeaders.Company]: TextInputSelectOption
  [JournalHeaders.IsInTransit]: boolean
  [JournalHeaders.IsUndisclosed]: boolean
  [JournalHeaders.ShareType]: string
}

type CreatedPricedObject = CreatedJournalObject & {
  [JournalHeaders.numberOfShares]: number
}
type CreatedLookbackObject = CreatedJournalObject & {
  [JournalHeaders.minCagr]: number
  [JournalHeaders.reservedShares]: number
}

type CreatedLookbackV2Object = CreatedLookbackObject & {
  [JournalHeaders.BasePrice]: number
}

type CompaniesToFundMap = {
  [fundId: number]: {
    [companyId: number]: boolean
  }
}
type Company = ArrayElement<RouterOutputs['companies']['allForSelector']>

type CreatedFundCommitObject = {
  [FundCommitHeaders.WireDate]: Date
  [FundCommitHeaders.SubscribeDate]: Date
  [FundCommitHeaders.Amount]: number
  [FundCommitHeaders.Entity]: DropdownSearchOption
  [FundCommitHeaders.ManagementFees]: number
  [FundCommitHeaders.CarryPercentage]: number
}

function isCompanyUndisclosed(fundId: number, companyId: number, companiesToFundsMap: CompaniesToFundMap) {
  return companiesToFundsMap[fundId][companyId] ?? false
}

type RouterCompanyToFund = ArrayElement<RouterOutputs['adminCompanyRouter']['getCompanyToFunds']>
export default function AdminSheetsPage() {
  let params = useParams()
  let selectedFundId = params.fundId ? parseInt(params.fundId) : 1
  const fundDetailsQuery = trpc.fund.fundDetails.useQuery(selectedFundId)

  const vanillaClient = useVanillaTRPC()
  const { isLoading: isAuthLoading, authStatus } = usePluralAuth()

  const isCompanyEditorQuery = trpc.attributedCompanyMetrics.isCompanyEditor.useQuery(undefined, {
    enabled: !isAuthLoading,
  })
  let allJournalEntriesQuery = trpc.journalEntries.getJournalEntries.useQuery(selectedFundId, {
    enabled: !isAuthLoading,
  })

  let allCompaniesToFundsQuery = trpc.adminCompanyRouter.getCompanyToFunds.useQuery(undefined, {
    enabled: !isAuthLoading,
  })

  let companiesToFundsMap = useMemo(() => {
    let map: CompaniesToFundMap = {
      1: {},
      2: {},
      3: {},
    }
    allCompaniesToFundsQuery.data?.forEach((companyToFunds: RouterCompanyToFund) => {
      map[companyToFunds.fundId][companyToFunds.companyId] = companyToFunds.is_undisclosed
    })
    return map
  }, [allCompaniesToFundsQuery.data])

  useEffect(() => {
    if (isAuthLoading) return
    isCompanyEditorQuery.refetch()
  }, [isAuthLoading, isCompanyEditorQuery])

  const companiesQuery = trpc.companies.allForAdminSheet.useQuery()
  const companies = useMemo(() => {
    return companiesQuery.data?.sort((a: Company, b: Company) => a.name.localeCompare(b.name)) ?? []
  }, [companiesQuery.data])

  const isCompanyEditor = isCompanyEditorQuery.data ?? undefined

  const entitiesQuery = trpc.user.adminGetAllEntities.useQuery(undefined, {
    enabled: !isAuthLoading,
  })

  const entities = useMemo(() => {
    return entitiesQuery.data?.entities.sort((a, b) => a.name.localeCompare(b.name)) ?? []
  }, [entitiesQuery.data])

  const isAllowedUser = authStatus === AuthStatus.Allowed
  const now = new Date()

  const checkIsReadyForInvestmentMuation = trpc.adminCompanyRouter.checkCompanyIsReadyForInvestment.useMutation()

  const sortFn = (a: RouterJournalEntry, b: RouterJournalEntry) =>
    (a.legalEntityCounterparty?.name ?? 'a').localeCompare(b.legalEntityCounterparty?.name ?? 'b')

  // new Date(a.entryDate).getTime() - new Date(b.entryDate).getTime() !== 0
  //   ? new Date(a.entryDate).getTime() - new Date(b.entryDate).getTime()
  //   : a.id - b.id
  const lookbackV1Columns: AnyColumnProps<RouterJournalEntry, CreatedLookbackObject>[] = [
    {
      header: JournalHeaders.Date,
      inputType: 'input',
      textCellFormat: DateCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<Date>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackContract.mutate({
          journalEntryId: row.id,
          data: {
            entryDate: newValue.toISOString(),
          },
        })
        allJournalEntriesQuery.refetch()
      },

      accessor: (row) => new Date(row.entryDate),
      minWidthPixels: 150,
      placeholder: '00/00/0000',
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.Date,
        defaultOption: createMarketOpenDate(now.getMonth(), now.getDate(), now.getFullYear()),
      },
    },
    {
      header: JournalHeaders.ContractDate,
      inputType: 'input',
      textCellFormat: DateCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<Date>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackContract.mutate({
          journalEntryId: row.id,
          data: {
            contractDate: newValue.toISOString(),
          },
        })
        allJournalEntriesQuery.refetch()
      },

      accessor: (row) => (!!row.contractDate ? new Date(row.contractDate) : undefined),
      minWidthPixels: 150,
      placeholder: '00/00/0000',
      createProps: {
        isRequired: false,
        createdObjectKey: JournalHeaders.ContractDate,
        defaultOption: createMarketOpenDate(now.getMonth(), now.getDate(), now.getFullYear()),
      },
    },
    {
      header: JournalHeaders.Entity,
      inputType: 'autoComplete',
      autoCompleteOptions: entities.map((entity, i) => ({
        label: entity.name,
        index: i,
        id: entity.id,
        additionalSearchString: `${entity.address} ${entity.name}`,
      })),
      accessor: (row) => {
        return {
          id: row.legalEntityCounterpartyId,
          index: entities.findIndex((entity) => entity.id === row.legalEntityCounterpartyId),
          label: entities.find((entity) => entity.id === row.legalEntityCounterpartyId)?.name ?? '',
        } as TextInputSelectOption
      },
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<TextInputSelectOption>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackContract.mutate({
          journalEntryId: row.id,
          data: {
            legalEntityCounterpartyId: (newValue.id ?? entities[newValue.index].id) as string,
          },
        })
        allJournalEntriesQuery.refetch()
      },
      placeholder: 'Legal Entity Name',
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.Entity,
      },
      minWidthPixels: 250,
    },
    {
      header: JournalHeaders.Company,
      minWidthPixels: 175,
      inputType: 'autoComplete',
      autoCompleteOptions: companies.map((company: Company, i: number) => ({
        label: company.name,
        index: i,
        id: company.id,
        additionalSearchString: company.domain,
      })),
      accessor: (row) => {
        return {
          id: row.lookbackContract?.companyId,
          index: companies.findIndex((company: Company) => company.id === row.lookbackContract?.companyId),
          label: companies.find((company: Company) => company.id === row.lookbackContract!.companyId)?.name ?? '',
        } as TextInputSelectOption
      },
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<TextInputSelectOption>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackContract.mutate({
          journalEntryId: row.id,
          data: {
            companyId: (newValue.id ?? companies[newValue.index].id) as number,
          },
        })
        allJournalEntriesQuery.refetch()
      },
      placeholder: 'Company',
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.Company,
      },
    },

    {
      minWidthPixels: 100,
      header: JournalHeaders.minCagr,
      inputType: 'input',
      accessor: (row) => row.lookbackContract?.minimumGrowthRate,
      textCellFormat: NumberCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<number>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackContract.mutate({
          journalEntryId: row.id,
          data: {
            minimumGrowthRate: newValue,
          },
        })
        allJournalEntriesQuery.refetch()
      },
      placeholder: 'Min Growth Rate',
      createProps: {
        defaultOption: 0.2,
        isRequired: true,
        createdObjectKey: JournalHeaders.minCagr,
      },
    },
    {
      minWidthPixels: 100,
      header: JournalHeaders.reservedShares,
      inputType: 'input',
      accessor: (row) => row.lookbackContract?.reservedShares,
      textCellFormat: NumberCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<number>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackContract.mutate({
          journalEntryId: row.id,
          data: {
            reservedShares: newValue,
          },
        })
        allJournalEntriesQuery.refetch()
      },
      placeholder: 'Reserved Shared',
      createProps: {
        defaultOption: undefined,
        isRequired: true,
        createdObjectKey: JournalHeaders.reservedShares,
      },
    },
    {
      minWidthPixels: 150,
      header: JournalHeaders.Amount,
      inputType: 'input',
      accessor: (row) => -row.cashDelta,
      textCellFormat: DollarsCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<number>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackContract.mutate({
          journalEntryId: row.id,
          data: {
            cashDelta: -newValue,
          },
        })
        allJournalEntriesQuery.refetch()
      },
      placeholder: 'Amount',
      createProps: {
        defaultOption: undefined,
        isRequired: true,
        createdObjectKey: JournalHeaders.Amount,
      },
    },
    {
      header: JournalHeaders.ShareType,
      createProps: {
        isRequired: false,
        createdObjectKey: JournalHeaders.ShareType,
      },
      inputType: 'multiSelect',
      options: (row) => {
        let companyFinancing =
          companies.find(
            (company: RouterCompany) =>
              company.id ===
              (row.lookbackContract?.companyId ?? (row as unknown as CreatedLookbackObject)['Company']?.id),
          )?.financingEvents ?? []
        return [
          'Common',
          ...companyFinancing.map((financing: ArrayElement<RouterCompany['financingEvents']>) => financing.shareType),
        ].filter((option) => option)
      },
      accessor: (row) => row.lookbackContract?.shareType ?? '',
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<string>) => {
        await vanillaClient.journalEntries.updateLookbackContract.mutate({
          journalEntryId: row.id,
          data: {
            shareType: newValue ?? undefined,
          },
        })
        allJournalEntriesQuery.refetch()
      },
    },
    {
      header: JournalHeaders.IsUndisclosed,
      inputType: 'boolean',
      accessor: (row) =>
        isCompanyUndisclosed(selectedFundId, row.lookbackContract?.companyId ?? -1, companiesToFundsMap),
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<boolean>) => {
        if (newValue == null) return
        await vanillaClient.adminCompanyRouter.setCompanyIsUndisclosed.mutate({
          companyId: row.lookbackContract?.companyId ?? -1,
          fundId: selectedFundId,
          is_undisclosed: newValue,
        })
        allCompaniesToFundsQuery.refetch()
      },
      createProps: {
        isRequired: false,
        createdObjectKey: JournalHeaders.IsUndisclosed,
        defaultOption: false,
      },
    },
    {
      header: JournalHeaders.IsInTransit,
      inputType: 'boolean',
      accessor: (row) => row.isInTransit ?? false,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<boolean>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackContract.mutate({
          journalEntryId: row.id,
          data: {
            isInTransit: newValue,
          },
        })
        allJournalEntriesQuery.refetch()
      },
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.IsInTransit,
        defaultOption: false,
      },
    },
  ]

  let lookbackContracts: RouterJournalEntry[] = (allJournalEntriesQuery.data?.journalEntries ?? []).filter((entry) => {
    return entry.lookbackContract != null
  })

  let lookbackV2Contracts: RouterJournalEntry[] = (allJournalEntriesQuery.data?.journalEntries ?? []).filter(
    (entry) => {
      return entry.lookbackV2Contract != null
    },
  )

  let pricedContractsIn = (allJournalEntriesQuery.data?.journalEntries ?? []).filter((entry) => {
    return entry.pricedCompanyEquityTransaction != null && entry.cashDelta < 0
  })
  let pricedContractsOut = (allJournalEntriesQuery.data?.journalEntries ?? []).filter((entry) => {
    return entry.pricedCompanyEquityTransaction != null && entry.cashDelta > 0
  })

  let [rowCreateError, setRowCreateError] = useState('')

  if ((!isAllowedUser || !isCompanyEditor) && !isAuthLoading) {
    return (
      <div className="mb-24 flex w-full flex-col items-center justify-start pl-4 pr-4 pt-4">
        <div className="opacity-60">You are not logged in as an allowed user.</div>
      </div>
    )
  }

  return (
    <div className="mb-24 flex w-full flex-col items-center justify-start p-12  pb-10">
      <div className={`mt-8 flex w-full flex-col items-center justify-center`}>
        <ErrorAlertModal
          title="Error creating Lookback Transaction"
          open={rowCreateError.length > 0}
          body={`This may cause issues with the portfolio page.  ${rowCreateError}`}
          onClose={() => {
            setRowCreateError('')
          }}
        />
        <div className=" w-full">
          {allJournalEntriesQuery.isLoading || isAuthLoading ? (
            <div className="loader mt-6 flex w-full flex-col items-center">
              <IonItem>
                <IonSpinner name="lines-sharp"></IonSpinner>
              </IonItem>
            </div>
          ) : (
            <div className="flex w-full flex-col gap-10">
              <div className="mb-4 flex w-full items-center justify-between">
                <div className="flex flex-row items-center gap-2 text-2xl">
                  Investments for {fundDetailsQuery.data?.name ?? 'Unknown'}
                </div>
                {/* <Select.Root
                  value={selectedFundId.toString()}
                  onValueChange={(value) => {
                    navigate(`/admin-sheet/${value}`)
                  }}>
                  <Select.Trigger
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                  <Select.Content>
                    <Select.Item value={'1'} key={'1'} className="pr-2">
                      Fund I
                    </Select.Item>
                    <Select.Item value={'2'} key={'2'} className="pr-2">
                      Fund II
                    </Select.Item>
                  </Select.Content>
                </Select.Root> */}
              </div>
              <div className="flex w-full flex-col gap-2">
                <div className="justify-left mb-2 flex flex-row items-center text-xl font-normal">
                  Lookback Contracts
                </div>
                <>
                  <EditableTable<RouterJournalEntry, CreatedLookbackObject>
                    stickyHeader
                    maxHeight="max-h-160"
                    onRowCreate={async (createdObject: CreatedLookbackObject) => {
                      let response = await checkIsReadyForInvestmentMuation.mutateAsync({
                        companyId: createdObject[JournalHeaders.Company].id as number,
                        date: createdObject[JournalHeaders.Date].toISOString(),
                        type: 'v1',
                      })
                      if (response.isReady) {
                        await vanillaClient.adminCompanyRouter.setCompanyIsUndisclosed.mutate({
                          companyId: createdObject[JournalHeaders.Company].id as number,
                          fundId: selectedFundId,
                          is_undisclosed: createdObject[JournalHeaders.IsUndisclosed],
                        })
                        await vanillaClient.journalEntries.createLookbackContract.mutate({
                          data: {
                            entryDate: createdObject[JournalHeaders.Date].toISOString(),
                            legalEntityCounterpartyId: (createdObject[JournalHeaders.Entity].id ??
                              entities[createdObject[JournalHeaders.Entity].index].id) as string,
                            cashDelta: -createdObject[JournalHeaders.Amount],
                            companyId: (createdObject[JournalHeaders.Company].id ??
                              companies[createdObject[JournalHeaders.Company].index].id) as number,
                            isInTransit: createdObject[JournalHeaders.IsInTransit],
                            fundId: selectedFundId,
                            reservedShares: createdObject[JournalHeaders.reservedShares],
                            minimumGrowthRate: createdObject[JournalHeaders.minCagr],
                            shareType: createdObject[JournalHeaders.ShareType],
                          },
                        })
                        allJournalEntriesQuery.refetch()
                      } else {
                        setRowCreateError(response.reason)
                      }
                    }}
                    columns={lookbackV1Columns}
                    rowData={lookbackContracts.sort(sortFn)}
                    deleteProps={{
                      onDelete: async (rowIndex) => {
                        await vanillaClient.journalEntries.deleteJournalEntry.mutate(lookbackContracts[rowIndex].id)
                        allJournalEntriesQuery.refetch()
                      },
                      confirmationModalProps: {
                        title: 'Are you sure you wish to delete this lookback contract',
                        body: 'This action is permanent and may impact the portfolio page.',
                      },
                    }}
                  />
                  <div className="flex flex-row items-center justify-between">
                    <CSVLink
                      filename={`fund_${selectedFundId}_lookbacks_${new Date().toLocaleDateString()}.csv`}
                      data={lookbackContracts.sort(sortFn).map((contract) => {
                        return {
                          entryDate: new Date(contract.entryDate).toLocaleDateString(),
                          legalEntity: entities.find((entity) => entity.id === contract.legalEntityCounterpartyId)
                            ?.name,
                          amount: -contract.cashDelta,
                          company: companies.find(
                            (company: Company) => company.id === contract.lookbackContract?.companyId,
                          )?.name,
                          isInTransit: contract.isInTransit,
                          minCagr: contract.lookbackContract?.minimumGrowthRate,
                          reservedShares: contract.lookbackContract?.reservedShares,
                        }
                      })}>
                      Download CSV
                    </CSVLink>
                    <div className="mr-3">
                      Total:{' '}
                      {numberToReadableDollarString(
                        lookbackContracts.reduce((acc, investment) => acc - investment.cashDelta, 0),
                      )}{' '}
                      invested
                    </div>
                  </div>
                </>
              </div>
              <div className="flex w-full flex-col gap-2">
                <div className="justify-left mb-2 flex flex-row items-center text-xl font-normal">
                  Lookback V2 Contracts
                </div>
                <LookbackV2Sheet
                  resetUndisclosed={() => {
                    allCompaniesToFundsQuery.refetch()
                  }}
                  companiesToFundsMap={companiesToFundsMap}
                  entities={entities}
                  companies={companies}
                  refetchQuery={() => {
                    allJournalEntriesQuery.refetch()
                  }}
                  selectedFundId={selectedFundId}
                  lookbackV2Contracts={lookbackV2Contracts.sort(sortFn)}
                />
              </div>

              <div className="flex w-full flex-col gap-2">
                <div className="justify-left mb-2 flex flex-row items-center text-xl font-normal">
                  Priced Positions Purchased
                </div>
                <PricedPositionTable
                  resetUndisclosed={() => {
                    allCompaniesToFundsQuery.refetch()
                  }}
                  companiesToFundsMap={companiesToFundsMap}
                  entities={entities}
                  companies={companies}
                  refetchQuery={() => {
                    allJournalEntriesQuery.refetch()
                  }}
                  isOutbound={false}
                  selectedFundId={selectedFundId}
                  pricedPositions={pricedContractsIn.sort(sortFn)}
                />
              </div>
              <div className="flex w-full flex-col gap-2">
                <div className="justify-left mb-2 flex flex-row items-center text-xl font-normal">
                  Priced Positions Sold
                </div>
                <PricedPositionTable
                  resetUndisclosed={() => {
                    allCompaniesToFundsQuery.refetch()
                  }}
                  companiesToFundsMap={companiesToFundsMap}
                  entities={entities}
                  companies={companies}
                  refetchQuery={() => {
                    allJournalEntriesQuery.refetch()
                  }}
                  isOutbound={true}
                  selectedFundId={selectedFundId}
                  pricedPositions={pricedContractsOut.sort(sortFn)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export function FundCommitsTable(props: {
  selectedFundId: number
  entities: RouterOutputs['user']['adminGetAllEntities']['entities']
}) {
  let allFundCommitsQuery = trpc.journalEntries.getFundCommitsQuery.useQuery(props.selectedFundId)
  let vanillaClient = useVanillaTRPC()
  let now = new Date()
  let fundCommits = allFundCommitsQuery.data?.fundCommits ?? []
  let columnHelper = createColumnHelper()
  let columns: ColumnDef<RouterFundCommit>[] = [
    // @ts-ignore
    columnHelper.accessor(
      // @ts-ignore
      'legalEntityId',
      {
        header: FundCommitHeaders.Entity,
        filterFn: 'entityFn' as FilterFnOption<unknown>,
        sortingFn: 'entityFn' as SortingFnOption<unknown>,
        id: 'entity',
        size: 250,
        meta: {
          inputType: 'autoComplete',
          // @ts-ignore
          updateRemote: async (row: RouterFundCommit, newValue: Optional<TextInputSelectOption>) => {
            if (newValue == null) return
            await vanillaClient.journalEntries.updateFundCommit.mutate({
              id: row.id,
              data: {
                legalEntityId: (newValue.id ?? props.entities[newValue.index].id) as string,
              },
            })
            allFundCommitsQuery.refetch()
          },
          placeholder: 'Legal Entity Name',
          createProps: {
            isRequired: true,
            // @ts-ignore
            createdObjectKey: FundCommitHeaders.Entity,
          },
          autoCompleteOptions: props.entities.map((entity, i) => ({
            label: entity.name,
            value: entity.id,
            additionalSearchString: `${entity.address} ${entity.name}`,
          })),
        },
      },
    ),
    // @ts-ignore
    columnHelper.accessor((row: RouterFundCommit) => new Date(row.wireDate), {
      header: FundCommitHeaders.WireDate,
      id: 'wireDate',
      sortingFn: 'dateFn' as SortingFnOption<unknown>,
      filterFn: 'dateFn' as FilterFnOption<unknown>,
      size: 150,
      meta: {
        inputType: 'input',
        textCellFormat: DateCellFormat,
        // @ts-ignore
        updateRemote: async (row: RouterFundCommit, newValue: Optional<Date>) => {
          if (newValue == null) return
          await vanillaClient.journalEntries.updateFundCommit.mutate({
            id: row.id,
            data: {
              wireDate: newValue.toISOString(),
            },
          })
          allFundCommitsQuery.refetch()
        },
        createProps: {
          isRequired: true,
          // @ts-ignore
          createdObjectKey: FundCommitHeaders.WireDate,
          defaultOption: createMarketOpenDate(now.getMonth(), now.getDate(), now.getFullYear()),
        },
      },
    }),
    // @ts-ignore
    columnHelper.accessor((row) => (row.legalDate ? new Date(row.legalDate) : null), {
      header: FundCommitHeaders.SubscribeDate,
      id: 'legalDate',
      sortingFn: 'dateFn' as SortingFnOption<unknown>,
      size: 150,
      filterFn: 'dateFn' as FilterFnOption<unknown>,
      meta: {
        inputType: 'input',
        textCellFormat: DateCellFormat,
        // @ts-ignore
        updateRemote: async (row: RouterFundCommit, newValue: Optional<Date>) => {
          await vanillaClient.journalEntries.updateFundCommit.mutate({
            id: row.id,
            data: {
              legalDate: newValue ? newValue.toISOString() : null,
            },
          })
          allFundCommitsQuery.refetch()
        },
        placeholder: 'Subscribe Date',
        createProps: {
          isRequired: false,
          // @ts-ignore
          createdObjectKey: FundCommitHeaders.SubscribeDate,
          defaultOption: undefined,
        },
      },
    }),
    // @ts-ignore
    columnHelper.accessor('cashDelta', {
      header: FundCommitHeaders.Amount,
      id: 'cashDelta',
      sortingFn: 'numberFn' as SortingFnOption<unknown>,
      filterFn: 'numberFn' as FilterFnOption<unknown>,
      size: 150,
      meta: {
        inputType: 'input',
        textCellFormat: DollarsCellFormat,
        // @ts-ignore
        updateRemote: async (row: RouterFundCommit, newValue: Optional<number>) => {
          if (newValue == null) return
          await vanillaClient.journalEntries.updateFundCommit.mutate({
            id: row.id,
            data: {
              cashDelta: newValue,
            },
          })
          allFundCommitsQuery.refetch()
        },
        placeholder: 'Amount',
        createProps: {
          defaultOption: undefined,
          isRequired: true,
          // @ts-ignore
          createdObjectKey: FundCommitHeaders.Amount,
        },
      },
    }),
    // @ts-ignore
    columnHelper.accessor('managementFeePercent', {
      id: 'managementFeePercent',
      sortingFn: 'numberFn' as SortingFnOption<unknown>,
      filterFn: 'numberFn' as FilterFnOption<unknown>,
      header: FundCommitHeaders.ManagementFees,
      meta: {
        inputType: 'input',
        textCellFormat: FullPercentCellFormat,
        // @ts-ignore
        updateRemote: async (row: RouterFundCommit, newValue: Optional<number>) => {
          if (newValue == null) return
          await vanillaClient.journalEntries.updateFundCommit.mutate({
            id: row.id,
            data: {
              managementFeePercent: newValue,
            },
          })
          allFundCommitsQuery.refetch()
        },
        placeholder: 'Mgmt Fees',

        createProps: {
          // @ts-ignore
          defaultOption: 2.0,
          isRequired: true,
          // @ts-ignore
          createdObjectKey: FundCommitHeaders.ManagementFees,
        },
      },
    }),
    // @ts-ignore
    columnHelper.accessor('carryPercent', {
      id: 'carryPercent',
      sortingFn: 'numberFn' as SortingFnOption<unknown>,
      filterFn: 'numberFn' as FilterFnOption<unknown>,
      header: FundCommitHeaders.CarryPercentage,
      meta: {
        inputType: 'input',
        textCellFormat: FullPercentCellFormat,
        // @ts-ignore
        updateRemote: async (row: RouterFundCommit, newValue: Optional<number>) => {
          if (newValue == null) return
          await vanillaClient.journalEntries.updateFundCommit.mutate({
            id: row.id,
            data: {
              carryPercent: newValue,
            },
          })
          allFundCommitsQuery.refetch()
        },
        placeholder: 'Carry %',
        createProps: {
          // @ts-ignore
          defaultOption: 20.0,
          isRequired: true,
          // @ts-ignore
          createdObjectKey: FundCommitHeaders.CarryPercentage,
        },
      },
    }),
    // @ts-ignore
    columnHelper.display({
      id: 'delete',
      header: '',
      size: 100,
      cell: (context) => (
        <DeleteCell
          onDelete={async () => {
            let row = context.row.original as RouterFundCommit
            await vanillaClient.journalEntries.deleteFundCommit.mutate(row.id)
            allFundCommitsQuery.refetch()
          }}
          confirmationModalProps={{
            title: 'Are you sure you wish to delete this Fund Commit?',
            body: 'This action is permanent and may impact the portfolio page.',
          }}
        />
      ),
    }),
  ]

  function entityIdToName(id: string) {
    return props.entities.find((e) => e.id === id)?.name ?? ''
  }

  return (
    <>
      <EditableTanstackTable<RouterFundCommit, CreatedFundCommitObject>
        filterFns={{
          entityFn: (row: Row<RouterFundCommit>, columnId: string, filterValue: string[]) => {
            return entityIdToName(row.original.legalEntityId).toLowerCase().includes(filterValue[0].toLowerCase())
          },
        }}
        sortingFns={{
          entityFn: (a: RouterFundCommit, b: RouterFundCommit, field: string) => {
            return entityIdToName(b.legalEntityId).localeCompare(entityIdToName(a.legalEntityId))
          },
        }}
        defaultSortingKey="id"
        defaultSorting={{ id: 'entity', desc: true }}
        columns={columns}
        stickyHeader
        maxHeight="max-h-160"
        data={fundCommits.sort((a: RouterFundCommit, b: RouterFundCommit) => {
          return entityIdToName(a.legalEntityId).localeCompare(entityIdToName(b.legalEntityId))
        })}
        onRowCreate={async (createdObject: CreatedFundCommitObject) => {
          await vanillaClient.journalEntries.createFundCommit.mutate({
            data: {
              wireDate: createdObject[FundCommitHeaders.WireDate].toISOString(),
              legalDate: createdObject[FundCommitHeaders.SubscribeDate]
                ? createdObject[FundCommitHeaders.SubscribeDate].toISOString()
                : undefined,
              cashDelta: createdObject[FundCommitHeaders.Amount],
              fundId: props.selectedFundId,
              legalEntityId: createdObject[FundCommitHeaders.Entity].value as string,
              managementFeePercent: createdObject[FundCommitHeaders.ManagementFees],
              carryPercent: createdObject[FundCommitHeaders.CarryPercentage],
            },
          })
          allFundCommitsQuery.refetch()
        }}
      />
      <div className="flex flex-row items-center justify-between">
        <CSVLink
          filename={`fund_${props.selectedFundId}_commits_${new Date().toLocaleDateString()}.csv`}
          data={fundCommits.map((commit) => {
            return {
              entity: props.entities.find((e) => e.id === commit.legalEntityId)?.name,
              wireDate: new Date(commit.wireDate).toLocaleDateString(),
              legalDate: commit.legalDate ? new Date(commit.legalDate).toLocaleDateString() : '',
              amount: commit.cashDelta,
              managementFees: commit.managementFeePercent,
              carry: commit.carryPercent,
            }
          })}>
          Download CSV
        </CSVLink>
        <div className="mr-3">
          Total: {numberToReadableDollarString(fundCommits.reduce((acc, commit) => acc + commit.cashDelta, 0))}
        </div>
      </div>
    </>
  )
}

function LookbackV2Sheet(props: {
  refetchQuery: () => void
  selectedFundId: number
  lookbackV2Contracts: RouterJournalEntry[]
  entities: RouterOutputs['user']['adminGetAllEntities']['entities']
  companies: RouterCompany[]
  companiesToFundsMap: CompaniesToFundMap
  resetUndisclosed: () => void
}) {
  const vanillaClient = useVanillaTRPC()
  const now = new Date()
  const checkIsReadyForInvestmentMuation = trpc.adminCompanyRouter.checkCompanyIsReadyForInvestment.useMutation()
  const lookbackV2Columns: AnyColumnProps<RouterJournalEntry, CreatedLookbackV2Object>[] = [
    {
      header: JournalHeaders.Date,
      inputType: 'input',
      textCellFormat: DateCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<Date>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackV2Contract.mutate({
          journalEntryId: row.id,
          data: {
            entryDate: newValue.toISOString(),
          },
        })
        props.refetchQuery()
      },

      accessor: (row) => new Date(row.entryDate),
      minWidthPixels: 150,
      placeholder: '00/00/0000',
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.Date,
        defaultOption: createMarketOpenDate(now.getMonth(), now.getDate(), now.getFullYear()),
      },
    },
    {
      header: JournalHeaders.ContractDate,
      inputType: 'input',
      textCellFormat: DateCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<Date>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackV2Contract.mutate({
          journalEntryId: row.id,
          data: {
            contractDate: newValue.toISOString(),
          },
        })
        props.refetchQuery()
      },

      accessor: (row) => (row.contractDate ? new Date(row.contractDate) : undefined),
      minWidthPixels: 150,
      placeholder: '00/00/0000',
      createProps: {
        isRequired: false,
        createdObjectKey: JournalHeaders.ContractDate,
        defaultOption: createMarketOpenDate(now.getMonth(), now.getDate(), now.getFullYear()),
      },
    },
    {
      header: JournalHeaders.Entity,
      inputType: 'autoComplete',
      autoCompleteOptions: props.entities.map((entity, i) => ({
        label: entity.name,
        index: i,
        id: entity.id,
        additionalSearchString: `${entity.address} ${entity.name}`,
      })),
      accessor: (row) => {
        return {
          id: row.legalEntityCounterpartyId,
          index: props.entities.findIndex((e) => e.id === row.legalEntityCounterpartyId),
          label: props.entities.find((e) => e.id === row.legalEntityCounterpartyId)?.name ?? '',
        } as TextInputSelectOption
      },
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<TextInputSelectOption>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackV2Contract.mutate({
          journalEntryId: row.id,
          data: {
            legalEntityCounterpartyId: (newValue.id ?? props.entities[newValue.index].id) as string,
          },
        })
        props.refetchQuery()
      },
      placeholder: 'Legal Entity Name',
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.Entity,
      },
      minWidthPixels: 250,
    },
    {
      header: JournalHeaders.Company,
      minWidthPixels: 175,
      inputType: 'autoComplete',
      autoCompleteOptions: props.companies.map((company, i) => ({
        label: company.name,
        index: i,
        id: company.id,
        additionalSearchString: company.domain,
      })),
      accessor: (row) => {
        return {
          id: row.lookbackV2Contract?.companyId,
          index: props.companies.findIndex((company) => company.id === row.lookbackV2Contract?.companyId),
          label: props.companies.find((company) => company.id === row.lookbackV2Contract!.companyId)?.name ?? '',
        } as TextInputSelectOption
      },
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<TextInputSelectOption>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackV2Contract.mutate({
          journalEntryId: row.id,
          data: {
            companyId: (newValue.id ?? props.companies[newValue.index].id) as number,
          },
        })
        props.refetchQuery()
      },
      placeholder: 'Company',
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.Company,
      },
    },
    {
      minWidthPixels: 150,
      header: JournalHeaders.Amount,

      inputType: 'input',
      accessor: (row) => -row.cashDelta,
      textCellFormat: DollarsCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<number>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackV2Contract.mutate({
          journalEntryId: row.id,
          data: {
            cashDelta: -newValue,
          },
        })
        props.refetchQuery()
      },
      placeholder: 'Amount',
      createProps: {
        defaultOption: undefined,
        isRequired: true,
        createdObjectKey: JournalHeaders.Amount,
      },
    },

    {
      minWidthPixels: 100,
      header: JournalHeaders.BasePrice,
      inputType: 'input',
      accessor: (row) => row.lookbackV2Contract?.basePrice,
      textCellFormat: DollarsCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<number>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackV2Contract.mutate({
          journalEntryId: row.id,
          data: {
            basePrice: newValue,
          },
        })
        props.refetchQuery()
      },
      placeholder: 'Base Price',
      createProps: {
        defaultOption: undefined,
        isRequired: true,
        createdObjectKey: JournalHeaders.BasePrice,
      },
    },
    {
      minWidthPixels: 100,
      header: JournalHeaders.minCagr,
      inputType: 'input',
      accessor: (row) => row.lookbackV2Contract?.minimumGrowthRate,
      textCellFormat: NumberCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<number>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackV2Contract.mutate({
          journalEntryId: row.id,
          data: {
            minimumGrowthRate: newValue,
          },
        })
        props.refetchQuery()
      },
      placeholder: 'Min Growth Rate',
      createProps: {
        defaultOption: undefined,
        isRequired: true,
        createdObjectKey: JournalHeaders.minCagr,
      },
    },
    {
      minWidthPixels: 100,
      header: JournalHeaders.reservedShares,
      inputType: 'input',
      accessor: (row) => row.lookbackV2Contract?.reservedShares,
      textCellFormat: NumberCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<number>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackV2Contract.mutate({
          journalEntryId: row.id,
          data: {
            reservedShares: newValue,
          },
        })
        props.refetchQuery()
      },
      placeholder: 'Reserved Shared',
      createProps: {
        defaultOption: undefined,
        isRequired: true,
        createdObjectKey: JournalHeaders.reservedShares,
      },
    },
    {
      header: JournalHeaders.ShareType,
      inputType: 'multiSelect',
      createProps: {
        isRequired: false,
        createdObjectKey: JournalHeaders.ShareType,
      },
      placeholder: 'Share Type',
      options: (row) => {
        let companyFinancing =
          props.companies.find(
            (company: RouterCompany) =>
              company.id ===
              (row.lookbackV2Contract?.companyId ?? (row as unknown as CreatedLookbackV2Object)['Company']?.id),
          )?.financingEvents ?? []
        return [
          'Common',
          ...companyFinancing.map((financing: ArrayElement<RouterCompany['financingEvents']>) => financing.shareType),
        ].filter((option) => option)
      },
      accessor: (row) => row.lookbackV2Contract?.shareType ?? '',
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<string>) => {
        await vanillaClient.journalEntries.updateLookbackV2Contract.mutate({
          journalEntryId: row.id,
          data: {
            shareType: newValue ?? undefined,
          },
        })
        props.refetchQuery()
      },
    },
    {
      header: JournalHeaders.IsUndisclosed,
      inputType: 'boolean',
      accessor: (row) =>
        isCompanyUndisclosed(props.selectedFundId, row.lookbackV2Contract?.companyId ?? -1, props.companiesToFundsMap),
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<boolean>) => {
        if (newValue == null) return
        await vanillaClient.adminCompanyRouter.setCompanyIsUndisclosed.mutate({
          companyId: row.lookbackV2Contract?.companyId ?? -1,
          fundId: props.selectedFundId,
          is_undisclosed: newValue,
        })
        props.resetUndisclosed()
        props.refetchQuery()
      },
      createProps: {
        isRequired: false,
        createdObjectKey: JournalHeaders.IsUndisclosed,
        defaultOption: false,
      },
    },
    {
      header: JournalHeaders.IsInTransit,
      inputType: 'boolean',
      accessor: (row) => row.isInTransit ?? false,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<boolean>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackV2Contract.mutate({
          journalEntryId: row.id,
          data: {
            isInTransit: newValue,
          },
        })
        props.refetchQuery()
      },
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.IsInTransit,
        defaultOption: false,
      },
    },
  ]

  const [rowCreateError, setRowCreateError] = useState('')
  return (
    <>
      <ErrorAlertModal
        title="Error creating Lookback V2 Transaction"
        open={rowCreateError.length > 0}
        body={`This may cause issues with the portfolio page.  ${rowCreateError}`}
        onClose={() => {
          setRowCreateError('')
        }}
      />
      <EditableTable<RouterJournalEntry, CreatedLookbackV2Object>
        stickyHeader
        maxHeight="max-h-160"
        onRowCreate={async (createdObject: CreatedLookbackV2Object) => {
          let response = await checkIsReadyForInvestmentMuation.mutateAsync({
            companyId: createdObject[JournalHeaders.Company].id as number,
            date: createdObject[JournalHeaders.Date].toISOString(),
            type: 'v2',
          })
          if (response.isReady) {
            await vanillaClient.adminCompanyRouter.setCompanyIsUndisclosed.mutate({
              companyId: createdObject[JournalHeaders.Company].id as number,
              fundId: props.selectedFundId,
              is_undisclosed: createdObject[JournalHeaders.IsUndisclosed],
            })
            await vanillaClient.journalEntries.createLookbackV2Contract.mutate({
              data: {
                entryDate: createdObject[JournalHeaders.Date].toISOString(),
                legalEntityCounterpartyId: (createdObject[JournalHeaders.Entity].id ??
                  props.entities[createdObject[JournalHeaders.Entity].index].id) as string,
                cashDelta: -createdObject[JournalHeaders.Amount],
                companyId: (createdObject[JournalHeaders.Company].id ??
                  props.companies[createdObject[JournalHeaders.Company].index].id) as number,
                isInTransit: createdObject[JournalHeaders.IsInTransit],
                fundId: props.selectedFundId,
                basePrice: createdObject[JournalHeaders.BasePrice],
                minimumGrowthRate: createdObject[JournalHeaders.minCagr],
                reservedShares: createdObject[JournalHeaders.reservedShares],
                shareType: createdObject[JournalHeaders.ShareType],
              },
            })
            props.refetchQuery()
          } else {
            setRowCreateError(response.reason)
          }
        }}
        columns={lookbackV2Columns}
        rowData={props.lookbackV2Contracts.sort((a, b) =>
          new Date(a.entryDate).getTime() - new Date(b.entryDate).getTime() !== 0
            ? new Date(a.entryDate).getTime() - new Date(b.entryDate).getTime()
            : a.id - b.id,
        )}
        deleteProps={{
          onDelete: async (rowIndex) => {
            await vanillaClient.journalEntries.deleteJournalEntry.mutate(props.lookbackV2Contracts[rowIndex].id)
            props.refetchQuery()
          },
          confirmationModalProps: {
            title: 'Are you sure you wish to delete this Lookback V2 Contract?',
            body: 'This action is permanent and may impact the portfolio page.',
          },
        }}
      />
      <div className="flex flex-row items-center justify-between">
        <CSVLink
          filename={`fund_${props.selectedFundId}_lookback_v2s_${new Date().toLocaleDateString()}.csv`}
          data={props.lookbackV2Contracts.map((contract) => {
            return {
              entity: props.entities.find((e) => e.id === contract.legalEntityCounterpartyId)?.name,
              entryDate: new Date(contract.entryDate).toLocaleDateString(),
              amount: -contract.cashDelta,
              basePrice: contract.lookbackV2Contract?.basePrice,
              minCagr: contract.lookbackV2Contract?.minimumGrowthRate,
              isInTransit: contract.isInTransit,
              company: props.companies.find((company) => company.id === contract.lookbackV2Contract?.companyId)?.name,
              sharesReserved: contract.lookbackV2Contract?.reservedShares,
            }
          })}>
          Download CSV
        </CSVLink>
        <div className="mr-3">
          Total:{' '}
          {numberToReadableDollarString(
            props.lookbackV2Contracts.reduce((acc, investment) => acc - investment.cashDelta, 0),
          )}{' '}
          invested
        </div>
      </div>
    </>
  )
}

function PricedPositionTable(props: {
  entities: RouterOutputs['user']['adminGetAllEntities']['entities']
  companies: RouterCompany[]
  refetchQuery: () => void
  isOutbound: boolean
  pricedPositions: RouterJournalEntry[]
  selectedFundId: number
  companiesToFundsMap: CompaniesToFundMap
  resetUndisclosed: () => void
}) {
  const vanillaClient = useVanillaTRPC()
  const now = new Date()
  const outboundMultiplier = props.isOutbound ? -1 : 1
  const pricedColumns: AnyColumnProps<RouterJournalEntry, CreatedPricedObject>[] = [
    {
      header: JournalHeaders.Date,
      inputType: 'input',
      textCellFormat: DateCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<Date>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updatePricedPosition.mutate({
          journalEntryId: row.id,
          data: {
            entryDate: newValue.toISOString(),
          },
        })
        props.refetchQuery()
      },

      accessor: (row) => new Date(row.entryDate),
      minWidthPixels: 150,
      placeholder: '00/00/0000',
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.Date,
        defaultOption: createMarketOpenDate(now.getMonth(), now.getDate(), now.getFullYear()),
      },
    },
    {
      header: JournalHeaders.ContractDate,
      inputType: 'input',
      textCellFormat: DateCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<Date>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updatePricedPosition.mutate({
          journalEntryId: row.id,
          data: {
            contractDate: newValue.toISOString(),
          },
        })
        props.refetchQuery()
      },

      accessor: (row) => (row.contractDate ? new Date(row.contractDate) : undefined),
      minWidthPixels: 150,
      placeholder: '00/00/0000',
      createProps: {
        isRequired: false,
        createdObjectKey: JournalHeaders.ContractDate,
        defaultOption: createMarketOpenDate(now.getMonth(), now.getDate(), now.getFullYear()),
      },
    },
    {
      header: JournalHeaders.Entity,
      inputType: 'autoComplete',
      autoCompleteOptions: props.entities.map((e, i) => ({
        label: e.name,
        index: i,
        id: e.id,
        additionalSearchString: `${e.address} ${e.name}`,
      })),
      accessor: (row) => {
        return {
          id: row.legalEntityCounterpartyId,
          index: props.entities.findIndex((e) => e.id === row.legalEntityCounterpartyId),
          label: props.entities.find((e) => e.id === row.legalEntityCounterpartyId)?.name ?? '',
        } as TextInputSelectOption
      },
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<TextInputSelectOption>) => {
        await vanillaClient.journalEntries.updatePricedPosition.mutate({
          journalEntryId: row.id,
          data: {
            legalEntityCounterpartyId: (newValue ? newValue.id ?? props.entities[newValue.index].id : null) as string,
          },
        })
        props.refetchQuery()
      },
      placeholder: 'Legal Entity Name',
      createProps: {
        isRequired: false,
        createdObjectKey: JournalHeaders.Entity,
      },
      minWidthPixels: 250,
    },
    {
      header: JournalHeaders.Company,
      minWidthPixels: 175,
      inputType: 'autoComplete',
      autoCompleteOptions: props.companies.map((company, i) => ({
        label: company.name,
        index: i,
        id: company.id,
        additionalSearchString: company.domain,
      })),
      accessor: (row) => {
        return {
          id: row.pricedCompanyEquityTransaction?.companyId,
          index: props.companies.findIndex((company) => company.id === row.pricedCompanyEquityTransaction?.companyId),
          label:
            props.companies.find((company) => company.id === row.pricedCompanyEquityTransaction!.companyId)?.name ?? '',
        } as TextInputSelectOption
      },
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<TextInputSelectOption>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updatePricedPosition.mutate({
          journalEntryId: row.id,
          data: {
            companyId: (newValue.id as number) ?? props.companies[newValue.index].id,
          },
        })
        props.refetchQuery()
      },
      placeholder: 'Company',
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.Company,
      },
    },
    {
      header: JournalHeaders.numberOfShares,
      inputType: 'input',
      textCellFormat: NumberCellFormat,
      accessor: (row) => row.pricedCompanyEquityTransaction?.numberOfShares,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<number>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updatePricedPosition.mutate({
          journalEntryId: row.id,
          data: {
            numberOfShares: newValue,
          },
        })
        props.refetchQuery()
      },
      placeholder: 'Number of Shares',
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.numberOfShares,
      },
    },
    {
      minWidthPixels: 150,
      header: JournalHeaders.Amount,
      inputType: 'input',
      accessor: (row) => -row.cashDelta * outboundMultiplier,
      textCellFormat: DollarsCellFormat,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<number>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updatePricedPosition.mutate({
          journalEntryId: row.id,
          data: {
            cashDelta: -newValue * outboundMultiplier,
          },
        })
        props.refetchQuery()
      },
      placeholder: 'Amount',
      createProps: {
        defaultOption: undefined,
        isRequired: true,
        createdObjectKey: JournalHeaders.Amount,
      },
    },
    {
      header: JournalHeaders.ShareType,
      inputType: 'multiSelect',
      createProps: {
        isRequired: false,
        createdObjectKey: JournalHeaders.ShareType,
      },
      options: (row) => {
        let companyFinancing =
          props.companies.find(
            (company: RouterCompany) =>
              company.id ===
              (row.pricedCompanyEquityTransaction?.companyId ?? (row as unknown as CreatedPricedObject)['Company']?.id),
          )?.financingEvents ?? []
        return [
          'Common',
          ...companyFinancing.map((financing: ArrayElement<RouterCompany['financingEvents']>) => financing.shareType),
        ].filter((option) => option)
      },
      accessor: (row) => row.pricedCompanyEquityTransaction?.shareType ?? '',
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<string>) => {
        await vanillaClient.journalEntries.updatePricedPosition.mutate({
          journalEntryId: row.id,
          data: {
            shareType: newValue ?? undefined,
          },
        })
        props.refetchQuery()
      },
    },
    {
      header: JournalHeaders.IsUndisclosed,
      inputType: 'boolean',
      accessor: (row) =>
        isCompanyUndisclosed(
          props.selectedFundId,
          row.pricedCompanyEquityTransaction?.companyId ?? -1,
          props.companiesToFundsMap,
        ),
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<boolean>) => {
        if (newValue == null) return
        await vanillaClient.adminCompanyRouter.setCompanyIsUndisclosed.mutate({
          companyId: row.pricedCompanyEquityTransaction?.companyId ?? -1,
          fundId: props.selectedFundId,
          is_undisclosed: newValue,
        })
        props.resetUndisclosed()
        props.refetchQuery()
      },
      createProps: {
        isRequired: false,
        createdObjectKey: JournalHeaders.IsUndisclosed,
        defaultOption: false,
      },
    },
    {
      header: JournalHeaders.IsInTransit,
      inputType: 'boolean',
      accessor: (row) => row.isInTransit ?? false,
      updateRemote: async (row: RouterJournalEntry, newValue: Optional<boolean>) => {
        if (newValue == null) return
        await vanillaClient.journalEntries.updateLookbackV2Contract.mutate({
          journalEntryId: row.id,
          data: {
            isInTransit: newValue,
          },
        })
        props.refetchQuery()
      },
      createProps: {
        isRequired: true,
        createdObjectKey: JournalHeaders.IsInTransit,
        defaultOption: false,
      },
    },
  ]

  const [rowCreateError, setRowCreateError] = useState('')
  const checkIsReadyForInvestmentMutation = trpc.adminCompanyRouter.checkCompanyIsReadyForInvestment.useMutation()

  return (
    <>
      <ErrorAlertModal
        title="Error creating Priced Transaction"
        open={rowCreateError.length > 0}
        body={`This may cause issues with the portfolio page.  ${rowCreateError}`}
        onClose={() => {
          setRowCreateError('')
        }}
      />
      <EditableTable<RouterJournalEntry, CreatedPricedObject>
        stickyHeader
        maxHeight="max-h-160"
        onRowCreate={async (createdObject: CreatedPricedObject) => {
          let response = await checkIsReadyForInvestmentMutation.mutateAsync({
            companyId: createdObject[JournalHeaders.Company].id as number,
            date: createdObject[JournalHeaders.Date].toISOString(),
            type: 'priced',
          })
          if (response.isReady) {
            await vanillaClient.adminCompanyRouter.setCompanyIsUndisclosed.mutate({
              companyId: createdObject[JournalHeaders.Company].id as number,
              fundId: props.selectedFundId,
              is_undisclosed: createdObject[JournalHeaders.IsUndisclosed],
            })
            await vanillaClient.journalEntries.createPricedPosition.mutate({
              data: {
                entryDate: createdObject[JournalHeaders.Date].toISOString(),
                legalEntityCounterpartyId:
                  (createdObject[JournalHeaders.Entity].id as string) ??
                  props.entities[createdObject[JournalHeaders.Entity].index]?.id,
                cashDelta: -createdObject[JournalHeaders.Amount] * outboundMultiplier,
                companyId:
                  (createdObject[JournalHeaders.Company]?.id as number) ??
                  props.companies[createdObject[JournalHeaders.Company].index].id,
                numberOfShares: createdObject[JournalHeaders.numberOfShares],
                isInTransit: createdObject[JournalHeaders.IsInTransit],
                fundId: props.selectedFundId,
                shareType: createdObject[JournalHeaders.ShareType],
              },
            })
            props.refetchQuery()
          } else {
            setRowCreateError(response.reason)
          }
        }}
        columns={pricedColumns}
        rowData={props.pricedPositions.sort((a, b) =>
          new Date(a.entryDate).getTime() - new Date(b.entryDate).getTime() !== 0
            ? new Date(a.entryDate).getTime() - new Date(b.entryDate).getTime()
            : a.id - b.id,
        )}
        deleteProps={{
          onDelete: async (rowIndex) => {
            await vanillaClient.journalEntries.deleteJournalEntry.mutate(props.pricedPositions[rowIndex].id)
            props.refetchQuery()
          },
          confirmationModalProps: {
            title: 'Are you sure you wish to delete this Lookback V2 Contract?',
            body: 'This action is permanent and may impact the portfolio page.',
          },
        }}
      />
      <div className="flex flex-row items-center justify-between">
        <CSVLink
          filename={`fund_${props.selectedFundId}_priced_positions_${outboundMultiplier > 0 ? 'in' : 'out'}_${new Date().toLocaleDateString()}.csv`}
          data={props.pricedPositions.map((contract) => {
            return {
              entity: props.entities.find((e) => e.id === contract.legalEntityCounterpartyId)?.name,
              entryDate: new Date(contract.entryDate).toLocaleDateString(),
              amount: -contract.cashDelta * outboundMultiplier,
              numberOfShares: contract.pricedCompanyEquityTransaction?.numberOfShares,
              company: props.companies.find(
                (company) => company.id === contract.pricedCompanyEquityTransaction?.companyId,
              )?.name,
              isInTransit: contract.isInTransit,
            }
          })}>
          Download CSV
        </CSVLink>
        <div className="mr-3">
          Total:{' '}
          {numberToReadableDollarString(
            props.pricedPositions.reduce((acc, investment) => acc - investment.cashDelta, 0),
          )}{' '}
          invested
        </div>
      </div>
    </>
  )
}

export function UserSheet(props: { activeFundId?: number }) {
  const { isLoading: isAuthLoading, authStatus } = usePluralAuth()
  const isCompanyEditorQuery = trpc.attributedCompanyMetrics.isCompanyEditor.useQuery(undefined, {
    enabled: !isAuthLoading,
  })
  const isCompanyEditor = isCompanyEditorQuery.data ?? false
  const vanillaClient = useVanillaTRPC()
  const allUsersQuery = trpc.user.adminGetAllUsers.useQuery(undefined, {
    enabled: !isAuthLoading,
  })
  const users = allUsersQuery.data?.users ?? []
  const userColumns: AnyColumnProps<RouterUser, CreatedUserObject>[] = [
    {
      header: UserHeaders.Email,
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.email,
      updateRemote: async (row: RouterUser, newValue: Optional<string>) => {
        if (newValue == null) return
        await vanillaClient.user.adminUpdateUser.mutate({
          id: row.id,
          data: {
            email: newValue,
          },
        })
        allUsersQuery.refetch()
      },
      placeholder: 'Email',
      minWidthPixels: 300,
      createProps: {
        isRequired: true,
        createdObjectKey: UserHeaders.Email,
      },
    },
    {
      header: UserHeaders.FirstName,
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.firstName,
      updateRemote: async (row: RouterUser, newValue: Optional<string>) => {
        await vanillaClient.user.adminUpdateUser.mutate({
          id: row.id,
          data: {
            firstName: newValue,
          },
        })
        allUsersQuery.refetch()
      },
      placeholder: 'First Name',
      createProps: {
        isRequired: false,
        createdObjectKey: UserHeaders.FirstName,
      },
    },
    {
      header: UserHeaders.LastName,
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.lastName,
      updateRemote: async (row: RouterUser, newValue: Optional<string>) => {
        await vanillaClient.user.adminUpdateUser.mutate({
          id: row.id,
          data: {
            lastName: newValue,
          },
        })
        allUsersQuery.refetch()
      },
      placeholder: 'Last Name',
      createProps: {
        isRequired: false,
        createdObjectKey: UserHeaders.LastName,
      },
    },
    {
      header: UserHeaders.Handle,
      inputType: 'input',
      textCellFormat: StringCellFormat,
      accessor: (row) => row.handle,
      updateRemote: async (row: RouterUser, newValue: Optional<string>) => {
        await vanillaClient.user.adminUpdateUser.mutate({
          id: row.id,
          data: {
            handle: newValue,
          },
        })
        allUsersQuery.refetch()
      },
      placeholder: 'Handle',
      createProps: {
        isRequired: true,
        createdObjectKey: UserHeaders.Handle,
      },
    },
  ]
  return (
    <EditableTable<RouterUser, CreatedUserObject>
      stickyHeader
      maxHeight="max-h-160"
      columns={userColumns}
      rowData={users.filter(
        (user) =>
          user.legalEntitiesToUser.find(
            (ute: ArrayElement<RouterUser['legalEntitiesToUser']>) =>
              ute.legalEntity.journalEntries.find(
                (
                  entry: ArrayElement<ArrayElement<RouterUser['legalEntitiesToUser']>['legalEntity']['journalEntries']>,
                ) => (props.activeFundId ? entry.fundId === props.activeFundId : true),
              ) ||
              ute.legalEntity.fundCommits.find(
                (
                  commit: ArrayElement<ArrayElement<RouterUser['legalEntitiesToUser']>['legalEntity']['fundCommits']>,
                ) => (props.activeFundId ? commit.fundId === props.activeFundId : true),
              ),
          ) != null,
      )}
      onRowCreate={(createdObject) => {
        vanillaClient.user.adminCreateUser
          .mutate({
            email: createdObject[UserHeaders.Email],
            firstName: createdObject[UserHeaders.FirstName],
            lastName: createdObject[UserHeaders.LastName],
            handle: createdObject[UserHeaders.Handle],
          })
          .then(() => {
            allUsersQuery.refetch()
          })
      }}
    />
  )
}

export function RecalculateButton() {
  let vanillaClient = useVanillaTRPC()
  let [isRecalculating, setIsRecalculating] = useState(false)
  useEffect(() => {
    if (isRecalculating) {
      setTimeout(() => {
        setIsRecalculating(false)
      }, 80000)
    }
  }, [isRecalculating])
  return (
    <div className="mt-2 flex flex-col items-center text-sm">
      <Button
        disabled={isRecalculating}
        className="text-sm"
        color="gray"
        onClick={() => {
          vanillaClient.fundSnapshot.recalculate.query().then(() => {
            setIsRecalculating(true)
          })
        }}>
        Recalculate Portfolio Page
      </Button>
      {isRecalculating && <div>Recalculating...will take a min</div>}
    </div>
  )
}
